import Translate from '../resource/data/field-translate.json';
import GlobalString from '../resource/lang/ko.json';
import TaskInfo from '../resource/data/tasks.json';
import PTSFile from '../data/model/common/PTSFile';
import ValidateErrorObject from '../data/model/common/ValidateErrorObject';
import dayjs from 'dayjs';
import dotenv from 'dotenv';
import { v4 as uuid } from 'uuid';
import ManufactureItem from '../data/model/manufacture/ManufactureItem';
import * as XLSX from 'xlsx';

class Helper {
    static fieldToString = (f: string): string => {
        return (Translate as any)[f] || f;
    }
    static stringToField = (s: string): string => {
        return Object.keys(Translate).find(key => (Translate as any)[key] === s) || s;
    }

    static taskFieldToString = (f: string): string => {
        return (TaskInfo as any).translate[f] || f;
    }
    static taskStringToField = (s: string): string => {
        return Object.keys((TaskInfo as any).translate).find(key => (TaskInfo as any)[key] === s) || s;
    }

    static generatePTSFile = (file: File): PTSFile => {
        let obj = new PTSFile();
        if (!file) {
            return obj;
        }
        obj.name = file.name;
        obj.lastModified = file.lastModified;
        obj.size = file.size;
        obj.mime = file.type;
        return obj;
    }

    static generatePTSFileWithUniqueKey = (file: File, key: string): PTSFile => {
        let obj = new PTSFile();
        if (!file) {
            return obj;
        }
        obj.name = file.name;
        obj.lastModified = file.lastModified;
        obj.size = file.size;
        obj.mime = file.type;
        obj.uniqueKey = key;
        return obj;
    }

    static generateRandomKey = (): number => {
        const rand = Math.random();
        return rand;
    }

    static generateRandomString = (): string => {
        const rand = Math.random();
        return rand.toString(36).substr(2, 9);
    }

    static getTaskFormWork = (work: any, type: string): object => {
        try {
            //@ts-ignore
            const task = (work.tasks as any).filter(x => x.type === type);
            return task[0];
        } catch (err) {
            return null;
        }
    }

    static taskTypeToDepartType = (taskType: string): string => {
        try {
            return (TaskInfo.depart as any)[taskType];
        } catch (err) {
            return '';
        }
    }

    static getTooltip = (name: string): string => {
        try {
            return (GlobalString.tooltip as any)[name];
        } catch (err) {
            return null;
        }
    }

    static getErrorText = (obj: ValidateErrorObject) => {
        try {
            return obj.isError ? obj.errorText : '';
        } catch (err) {
            return '';
        }
    }

    static getErrorState = (obj: ValidateErrorObject) => {
        try {
            return obj.isError || false;
        } catch (err) {
            return false;
        }
    }

    static checkRegex = (str: string, pattern: string | RegExp) => {
        return str.match(pattern);
    }

    static getFormattedDate = (date: Date) => {
        if (date) {
            const day = dayjs(date);
            return day.isValid() ? day.format('YYYY-MM-DD HH:mm:ss') : '';
        }
        else {
            return '';
        }
    }

    static isAdmin = (role: string) => {
        return role.toLowerCase() === 'admin';
    }

    static isManager = (role: string) => {
        return role.toLowerCase() === 'manager';
    }

    static isBipart = (departType: string) => {
        return departType.toLowerCase() === 'bipart';
    }

    static getExtension = (fileName: string) => {
        if (fileName.indexOf('.') >= 0) {
            return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length).toLowerCase();
        }
    }

    static checkExtensionRegex = (fileName: string, extension: string | string[]) => {
        const ext = Helper.getExtension(fileName);
        if (ext && extension) {
            if (Array.isArray(extension)) {
                return extension.some(v => ext === v);
            }
            else {
                return ext === extension;
            }
        }
        else {
            return false;
        }
    }

    static setEnvs = (env: string) => {
        if (env === 'development') {
            dotenv.config({ path: '.env.development.local' });
        }
        else if (env === 'production') {
            dotenv.config({ path: '.env.production.local' });
        }
        else if (env === 'test') {
            dotenv.config({ path: '.env.test.local' });
        }
        else {
            dotenv.config();
        }
    }

    static getUUID = () => {
        return uuid();
    }

    static padString = (str: string, pad: string = '0', times: number = 1, position: 'left' | 'right' = 'left') => {
        let result = '';
        for (let i = 0; times > i; i++) {
            result += pad;
        }
        if (position === 'left') {
            return `${result}${str}`;
        }
        else {
            return `${str}${result}`;
        }
    }

    static padZeroCatNORxn = (catNO: string | null) => {
        const reg = /^.+[-]{1}[0-9]{1}\D?$/;
        if (catNO === null || catNO === undefined) {
            return null;
        }
        if (catNO.match(reg)) {
            const str = catNO.split('-');
            const digit = Helper.padString(str[str.length - 1], '0', 1, 'left');
            str[str.length - 1] = digit;
            return str.join('-');
        }
        else return catNO;
    }

    static generateLabelXlsxFile(items: ManufactureItem[]) {
        let infoList = items.map((v) => {
            return {
                N: v.itemOptions.getItemBoxLabelName(),
                probe: '',
                C: v.manipulatedCatNO,
                L: v.lotNO,
                S: (ManufactureItem.StorageTempList as any)[v.storageTemp],
                EXP: dayjs(v.expDate).format('YYYY-MM'),
            }
        });
        infoList = infoList.filter(v => v.N !== null)

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(infoList);

        XLSX.utils.book_append_sheet(wb, ws, 'BOX 라벨');
        const buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        return new Blob([buffer]);
    }

    static intToCommaSeperatedNumberString = (str: string | number) => {
        try {
            let number;
            const reg = /[^0-9-]/g;
            const rep = str.toString().replace(reg, '');
            const parsed = parseInt(rep);
            if (isNaN(parsed)) {
                throw new Error("Value is not number");
            }

            number = parsed.toString();
            let spl = number.split('').reverse();

            let sign = '';
            if (spl[spl.length - 1] === '-') {
                sign = '-';
                spl = spl.slice(0, spl.length - 1);
            }

            let count = 1;
            const result: string[] = [];
            spl.forEach(v => {
                if (count === 1) {
                    result.push(v);
                }
                else {
                    if (count % 3 === 1) {
                        result.push(',');
                    }
                    result.push(v);
                }
                count++;
            });
            return sign + result.reverse().join('');
        } catch (err) {
            return str;
        }
    }

    static floatToCommaSeperateNumberString = (str: string | number) => {
        try {
            if (isNaN(parseFloat(str.toString()))) {
                throw new Error("Value is not number");
            }

            const spl = str.toString().split('.');

            let num1 = spl[0].split('').reverse();
            const num2 = spl[1];
            let sign = '';

            if (num1.length > 0) {
                if (num1[num1.length - 1] === '-') {
                    sign = '-';
                    if (num1.length === 1) {
                        num1 = ['0'];
                    }
                    else if (num1.length > 1) {
                        num1 = num1.slice(0, num1.length - 1);
                    }
                }
            }

            let count = 1;
            const result: string[] = [];

            num1.forEach(v => {
                if (v === '-') {

                }
                if (count === 1) {
                    result.push(v);
                }
                else {
                    if (count % 3 === 1) {
                        result.push(',');
                    }
                    result.push(v);
                }
                count++;
            });

            return sign + result.reverse().join('') + (num2 !== undefined ? ('.' + num2) : '');

        } catch (err) {
            console.log(err);
            return str;
        }
    }

    static commaSeperatedFloatStringToFloat = (str: string) => {
        try {
            let spl = str.replaceAll(',', '');
            if (isNaN(parseFloat(spl))) {
                throw new Error("Value is not number");
            }

            return spl;
        } catch (err) {
            return str;
        }
    }

    static commaSeperatedIntStringToInt = (str: string) => {
        try {
            let spl = str.replaceAll(',', '');
            if (isNaN(parseInt(spl))) {
                throw new Error("Value is not number");
            }
            return spl;
        } catch (err) {
            return str;
        }
    }
}

export default Helper;
