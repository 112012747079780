import React from 'react';
import { Paper, Grid, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import ChipDistributionInfo from '../../../../../../../../data/model/common/ChipDistributionInfo';
import FormUtil from '../../../../../../../../module/FormUtil';
import Helper from '../../../../../../../../module/Helper';

interface Props {
    data: ChipDistributionInfo;
    designName: string;
}

const ChipDistributionInputCell: React.FC<Props> = observer((props) => {
    const data = props.data;
    return (
        <React.Fragment>
            <Paper style={{ padding: 10, marginTop: 5, marginBottom: 5, background: '#FEF9E7' }} >
                <Grid item xl lg sm container direction='column'>
                    <Grid item xl lg sm>
                        <TextField
                            margin='normal'
                            value={data.CDSDID}
                            name='CDSDID'
                            onChange={data.onChangeDefault}
                            InputProps={{ readOnly: true }}
                            label={FormUtil.getFieldLabel('CDSDID')} />
                        <TextField
                            margin='normal'
                            value={`${data.CDPanelName}`}
                            name='CDPanelName'
                            InputProps={{ readOnly: true }}
                            onChange={data.onChangeDefault}
                            label={FormUtil.getFieldLabel('CDPanelName')} />
                        <TextField
                            margin='normal'
                            value={data.chipID}
                            name='chipID'
                            onChange={data.onChangeDefault}
                            label={FormUtil.getFieldLabel('chipID')}
                            helperText={
                                Helper.getErrorText(
                                    data.errors.chipID
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    data.errors.chipID
                                )
                            } />
                        <TextField
                            margin='normal'
                            value={Helper.intToCommaSeperatedNumberString(data.CDNumberOfUniqueProbe)}
                            name='CDNumberOfUniqueProbe'
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            onChange={
                                e => {
                                    e.target.value = Helper.commaSeperatedIntStringToInt(e.target.value);
                                    data.onChangeDefault(e);
                                }
                            }
                            label={FormUtil.getFieldLabel('CDNumberOfUniqueProbe')}
                            helperText={
                                Helper.getErrorText(
                                    data.errors.CDNumberOfUniqueProbe
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    data.errors.CDNumberOfUniqueProbe
                                )
                            } />
                        <TextField
                            margin='normal'
                            value={Helper.intToCommaSeperatedNumberString(data.CDNumberOfRepeat)}
                            name='CDNumberOfRepeat'
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            onChange={
                                e => {
                                    e.target.value = Helper.commaSeperatedIntStringToInt(e.target.value);
                                    data.onChangeDefault(e);
                                }
                            }
                            label={FormUtil.getFieldLabel('CDNumberOfRepeat')}
                            helperText={
                                Helper.getErrorText(
                                    data.errors.CDNumberOfRepeat
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    data.errors.CDNumberOfRepeat
                                )
                            } />
                        <TextField
                            margin='normal'
                            value={Helper.intToCommaSeperatedNumberString(data.CDNumberOfFinalProbe) || ''}
                            name='CDNumberOfFinalProbe'
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            onChange={
                                e => {
                                    e.target.value = Helper.commaSeperatedIntStringToInt(e.target.value);
                                    data.onChangeDefault(e);
                                }
                            }
                            label={FormUtil.getFieldLabel('CDNumberOfFinalProbe')}
                            helperText={
                                Helper.getErrorText(
                                    data.errors.CDNumberOfFinalProbe
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    data.errors.CDNumberOfFinalProbe
                                )
                            } />
                        <TextField
                            margin='normal'
                            value={data.CDFlankSequence}
                            name='CDFlankSequence'
                            onChange={data.onChangeDefault}
                            label={FormUtil.getFieldLabel('CDFlankSequence')}
                            helperText={
                                Helper.getErrorText(
                                    data.errors.CDFlankSequence
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    data.errors.CDFlankSequence
                                )
                            } />
                        <TextField
                            margin='normal'
                            value={data.CDMixRatio}
                            type='number'
                            name='CDMixRatio'
                            onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                            onChange={data.onChangeDefault}
                            label={FormUtil.getFieldLabel('CDMixRatio')}
                            helperText={
                                Helper.getErrorText(
                                    data.errors.CDMixRatio
                                )
                            }
                            error={
                                Helper.getErrorState(
                                    data.errors.CDMixRatio
                                )
                            } />
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    )
})

export default ChipDistributionInputCell;